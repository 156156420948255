import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/coupon/currencyDyamics'


const getCurrencyListApi =(params)=>getAction(baseURL+"/getCurrencyList",params);
const getCurrencyDataListApi =(params)=>getAction(baseURL+"/getCurrencyDataList",params);

export{
    getCurrencyListApi,
    getCurrencyDataListApi
}
