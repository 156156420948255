<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
  >
    <el-form layout="inline" :model="queryParam" :inline="true">
      <el-row :gutter="24">
        <el-col>
          <el-form-item label="选择货币">
            <el-select
              v-model="queryParam.code"
              placeholder="请选择"
              @change="getCode"
            >
              <el-option
                v-for="item in currencyList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <kImg ref="kImgFrom" />
    <div>
      <h2 style="color: red">
        温馨提示:以中国银行各分行实际交易汇率为准，不构成投资建议。投资者据此买卖，风险自担
      </h2>
    </div>
  </el-dialog>
</template>
<script>
import { getCurrencyListApi,getCurrencyDataListApi } from "@/api/coupon/currencyDyamics.js";
import kImg from "@/views/otherUnits/kImg";
export default {
  components: {
    kImg,
  },
  data() {
    return {
      title: null,
      dialogVisible: false,
      currencyList: [],
      queryParam: {},
    };
  },
  methods: {
    //打开弹窗 1新增 2编辑
    show(row, type) {
      this.dialogVisible = true;
      this.title = "编辑";
      this.initData();
    },
    cancel() {
      this.dialogVisible = false;
      //调用父组件的查询
      this.$parent.selectListButton();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.cancel();
        })
        .catch((_) => {});
    },
    getCode(value) {
        console.log(value)
        getCurrencyDataListApi({code:value}).then(res=>{
            this.detailsButton(res.result)

        })
    },
    initData() {
        getCurrencyListApi().then(res=>{
            this.currencyList=res.result
        })
    },
    detailsButton(row) {
      this.$nextTick(() => {
        this.$refs.kImgFrom.data0 = row;
        this.$refs.kImgFrom.show();
      });
    },
  },
};
</script>
   <style lang="less" scoped>
</style>